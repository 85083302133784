arrowWidth = 6px

.ql-bubble
  .ql-toolbar
    .ql-formats
      margin: 8px 12px 8px 0px
    .ql-formats:first-child
      margin-left: 12px

  .ql-color-picker
    svg
      margin: 1px
    .ql-picker-item.ql-selected, .ql-picker-item:hover
      border-color: activeColor
